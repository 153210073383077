export const SET_SEARCH_ASSET_DATA_HISTORY = "SET_SEARCH_ASSET_DATA_HISTORY";
export const DATA_FETCHING_HISTORY_INIT = "DATA_FETCHING_HISTORY_INIT";
export const DATA_HISTORY_FETCHING_DISMISS = "DATA_HISTORY_FETCHING_DISMISS";
export const ASSET_HISTORY_SEARCH_LIST_COMPLETE = "ASSET_HISTORY_SEARCH_LIST_COMPLETE";
export const OPEN_NEW_UPLOAD_POPUP = "OPEN_NEW_UPLOAD_POPUP";
export const CLOSE_NEW_UPLOAD_POPUP = "CLOSE_NEW_UPLOAD_POPUP";
export const DATA_FETCHING_INIT="DATA_FETCHING_INIT";
export const DATA_FETCHING_DISMISS="DATA_FETCHING_DISMISS";
export const AST_EVENT_TYPE_INIT = "AST_EVENT_TYPE_INIT";
export const AST_EVENT_TYPE_COMPLETE = "AST_EVENT_TYPE_COMPLETE";
export const AST_EVENT_TYPE_DISMISS = "AST_EVENT_TYPE_DISMISS";
export const ADD_ASSET_EVENT_INIT = "ADD_ASSET_EVENT_INIT";
export const ADD_ASSET_EVENT_COMPLETED = "ADD_ASSET_EVENT_COMPLETED";
export const ADD_ASSET_EVENT_DISSMISS = "ADD_ASSET_EVENT_DISSMISS";
export const EDIT_ASSET_EVENT_INIT = "EDIT_ASSET_EVENT_INIT";
export const EDIT_ASSET_EVENT_COMPLETED = "EDIT_ASSET_EVENT_COMPLETED";
export const EDIT_ASSET_EVENT_DISSMISS = "EDIT_ASSET_EVENT_DISSMISS";
export const DELETE_ASSET_EVENT_INIT="DELETE_ASSET_EVENT_INIT";
export const DELETE_ASSET_EVENT_COMPLETE="DELETE_ASSET_EVENT_COMPLETE";
export const DELETE_ASSET_EVENT_DISMISS="DELETE_ASSET_EVENT_DISMISS";
const searchHistoryAsset ={
    qsearch:"",
    refresh_flag:false,
    assetHistory:[],
    assetHistorySerch_refetch:false,
    export_support_flag_value:1,
    download_support_flag :false,
    viewImagepopup:false,
    tableFlag:false,
    messegeFlag:true,
    location:true,
    energySuplier:false,
    ownerShip:false,
    finance:false,
    firmware:false,
    Photo:false,
    option:[
        // { key: '1', text: 'Location'},
        // { key: '2', text: 'Energy Supplier'},
        { key: '3', text: 'Ownership'},{ key: '4', text: 'Finance'},
        { key: '5', text: 'Firmware'},{key:'6',text:'Photo'}
      ],
    dissable_button:false,
    defaultValue:['1'],
    header:['date','event','details','locations'],
    total_asset_count:" ",   
    count:100,
    list_files: ["date","event","mpxn","location"],
    ShowModal:false,
    total_image_list:"",
    image_list:[],
    eventType:"",
    assetId:"",
    showPopup:false,
    showDetails:"",
    image_page:1,
    image_perpage:24,
    image_refetch:false,
    data_fetching:false,
    event:"",
    ex_image_url:"",
    Excel_icon_Popup:false,
    Excel_popup_details:[],
    Csv_icon_Popup:false,
    Csv_popup_details:[],

    //Add edit delete events 
    add_event_popup:false,
    ast_event_type: "",
    ast_event_date: "",
    ast_supplier_code: "",
    ast_mpan: "",
    ast_mprn: "",
    ast_address: "",
    ast_postcode: "",
    ast_scrap_cod:"",
    ast_scrap_location:"",
    ast_add_btn_dsbl:false,
    asset_event_types:[],
    delete_event_popup:false,
    assetData:[],
    ast_dlt_btn_dsbl: false,
    edit_ast_event_type: "",
    edit_ast_event_date: "",
    edit_ast_supplier_code: "",
    edit_ast_mpan: "",
    edit_ast_mprn: "",
    edit_ast_address: "",
    edit_ast_postcode: "",
    edit_ast_scrap_cod:"",
    edit_ast_scrap_location:"",
    edit_event_popup:false,
    ast_edit_btn_dsbl:false,
    s_scrap_his_mpan:"",
    s_scrap_his_mprn:"",
}

export default (state = searchHistoryAsset, action = {}) => {
    switch (action.type) {
        case SET_SEARCH_ASSET_DATA_HISTORY:
            return { ...state, ...action.payload };
        case DATA_FETCHING_HISTORY_INIT:
            return { ...state, ...action.payload, refresh_flag: true,dissable_button:true };
        case DATA_HISTORY_FETCHING_DISMISS:
            return { ...state, ...action.payload, refresh_flag: false,dissable_button:false};
        case ASSET_HISTORY_SEARCH_LIST_COMPLETE:
            return { ...state, ...action.payload };
        case OPEN_NEW_UPLOAD_POPUP:
            return { ...state, ...action.payload };
        case CLOSE_NEW_UPLOAD_POPUP:
            return { ...state, ...action.payload };
        case DATA_FETCHING_INIT:
            return { ...state, ...action.payload, data_fetching: true };
        case DATA_FETCHING_DISMISS:
            return { ...state, ...action.payload, data_fetching: false };
        case AST_EVENT_TYPE_INIT:
            return { ...state, ...action.payload };
        case AST_EVENT_TYPE_COMPLETE:
            return { ...state, ...action.payload };
        case AST_EVENT_TYPE_DISMISS:
            return { ...state, ...action.payload };
        case  ADD_ASSET_EVENT_INIT:
            return { ...state, ...action.payload,ast_add_btn_dsbl: true, status_complete: false};
        case ADD_ASSET_EVENT_COMPLETED:
            return {
                ...state, ...action.payload,
                ast_add_btn_dsbl: false,
                add_event_popup: false,
                status_complete: true,
                ast_event_type: "",
                ast_event_date: "",
                ast_supplier_code: "",
                ast_mpan: "",
                ast_mprn: "",
                ast_address: "",
                ast_postcode: "",
                ast_scrap_cod: "",
                ast_scrap_location: "",
            };
        case ADD_ASSET_EVENT_DISSMISS:
            return { ...state, ...action.payload, ast_add_btn_dsbl: false, status_complete: false };
        case EDIT_ASSET_EVENT_INIT:
            return { ...state, ...action.payload, ast_edit_btn_dsbl: true, status_complete: false };
        case EDIT_ASSET_EVENT_COMPLETED:
            return {
                ...state, ...action.payload,
                ast_edit_btn_dsbl: false,
                edit_event_popup: false,
                status_complete: true
            };
        case EDIT_ASSET_EVENT_DISSMISS:
            return { ...state, ...action.payload, ast_edit_btn_dsbl: false, status_complete: false };
        case DELETE_ASSET_EVENT_INIT:
            return {
                ...state,
                ...action.payload,
                ast_dlt_btn_dsbl: true,
                status_complete: false,
            };
        case DELETE_ASSET_EVENT_COMPLETE:
            return {
                ...state,
                ...action.payload,
                ast_dlt_btn_dsbl: false,
                status_complete: true,
            }
        case DELETE_ASSET_EVENT_DISMISS:
            return {
                ...state,
                ...action.payload,
                ast_dlt_btn_dsbl: false,
                status_complete: false,
            }
                default:
            return state;
    }
}